import axios from "axios";
import langs from "../json/langs.json";
import dayjs from "dayjs";
import "dayjs/locale/fr";

/* Récupération des données d'une opération en fonction du hostname */
const getDataFromHost = async (host) => {
  try {
    if (
      !host ||
      host === "localhost" ||
      host === "192.168.68.206" ||
      host === "test.promo.dev" ||
      host === "test2.promo.dev"
    ) {
      return false;
    }
    const { data } = await axios.get(
      `${process.env.VUE_APP_ROOT_API}/api/v2/formdata/host/${host}`,
    );
    return data;
  } catch (error) {
    console.error("Error fetching data from host:", error);
    throw error;
  }
};

/* Récupération des données d'une opération en fonction du hostname */
const getSubmission = async () => {
  try {
    const url = new URL(window.location.href);
    if (url.pathname.includes("/status/")) {
      const parameters = url.pathname.split("/status/")[1].split("/");
      if (!parameters[0] || !parameters[1]) {
        return false;
      }
      const { data } = await axios.get(
        `${process.env.VUE_APP_ROOT_API}/api/v2/submissions/${parameters[0]}?uid=${parameters[1]}`,
      );
      return data;
    }
    return;
  } catch (error) {
    console.error("Error fetching data from host:", error);
    throw error;
  }
};

/* Récupération des données d'une opération en fonction de son _id mongo */
const getDataFromID = async (_id) => {
  try {
    if (!_id) {
      return false;
    }
    const { data } = await axios.get(
      `${process.env.VUE_APP_ROOT_API}/api/v2/formdata/${_id}`,
    );

    return data;
  } catch (error) {
    console.error("Error fetching data from ID:", error);
  }
};

/* Récupération du fichier multi-langue lié a l'operation sur digital ocean */
const getJsonLangs = (uri) => {
  return axios.get(uri);
};

/* Récupération des données du compteur pour une opération */
const getCounterValue = async (id, name) => {
  if (!id || !name) {
    throw new Error("Both 'id' and 'name' must be provided.");
  }
  return axios
    .get(`${process.env.VUE_APP_ROOT_API}/api/v2/counters/${id}/${name}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error fetching counter value:", error);
    });
};

/* Convertit un code couleur hexa en RGB */
const hexToRgbA = (hexColor, trans) => {
  if (!hexColor) hexColor = "#FF0000";
  if (!trans) trans = 0.1;
  hexColor = formatToHex(hexColor);
  if (/^#([\dA-Fa-f]{3}){1,2}$/.test(hexColor)) {
    const c = Number.parseInt(hexColor.slice(1), 16);
    const red = (c >> 16) & 255;
    const green = (c >> 8) & 255;
    const blue = c & 255;
    return `rgba(${red}, ${green}, ${blue}, ${trans})`;
  }
};

/* Formatage d'un code couleur hexadecimal */
const formatToHex = (hex = "#00000") => {
  return hex.charAt(0) === "#" ? hex : `#${hex}`;
};

const checkEnvironment = () => {
  const host = window.location.hostname;
  let environment = "PROD";
  if (
    host == "lucien-mb.netlify.app" ||
    host == "localhost" ||
    host === "192.168.68.206" ||
    host == "staging.promo.dev" ||
    host == "test.promo.dev" ||
    host == "test2.promo.dev"
  ) {
    environment = "DEV";
  }
  return environment;
};

const getCounter = async (counters, _id, compteur_limit_visible) => {
  if (counters?.[0]) {
    let counter = counters[0] || {};
    const datacounter = await getCounterValue(_id, counter.name);

    counter.total = Number.parseInt(
      datacounter[counter.increment ? "value" : "limit"],
    );

    counter.restant = Number.parseInt(datacounter.limit);
    if (counter.increment) {
      counter.restant = Number.parseInt(datacounter.value);
    }

    counter.percent = Math.floor((counter.restant / counter.limit) * 100);
    // counter.display = mb.compteur ?? counter.display;

    if (
      compteur_limit_visible &&
      compteur_limit_visible > 0 &&
      ((counter.type === "INFORMATIVE" &&
        counter.total < compteur_limit_visible) ||
        (counter.type !== "INFORMATIVE" &&
          counter.restant > compteur_limit_visible))
    ) {
      counter.display = "hidden";
    }
    return counter;
  }
};

const getFormsID = (forms, lang) => {
  /* GESTION DES FORMULAIRES */

  let status = forms.filter((status) => {
    return status.type === "UPDATE" && status.lang === lang;
  });

  if (status.length <= 0) {
    status = forms.filter((status) => {
      return status.type === "UPDATE";
    });
  }
  if (status.length <= 0) {
    status[0] = {};
  }

  let create = forms.filter((create) => {
    return (
      create.type === "CREATE" &&
      !create.paper &&
      !create.line_item &&
      create.lang === lang
    );
  });

  if (create.length <= 0) {
    create = forms.filter((create) => {
      return create.type === "CREATE" && !create.paper && !create.line_item;
    });
  }
  if (create.length <= 0) {
    create[0] = {};
  }

  return { statusID: status[0]._id, createID: create[0]._id };
};

/* insertion dans le head du DOM du cookie law dulux valentine */
const setDuluxCookies = () => {
  const scripts = [
    {
      type: "text/javascript",
      src: "https://cdn.cookielaw.org/consent/02129ad7-7494-4746-8765-b846a04e5188/OtAutoBlock.js",
    },
    {
      src: "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js",
    },
    {
      text: "function OptanonWrapper() { }",
    },
  ];

  for (const script of scripts) {
    const scriptElement = document.createElement("script");
    scriptElement.type = script.type;
    scriptElement.src = script.src;
    scriptElement.dataset.domainScript = "02129ad7-7494-4746-8765-b846a04e5188";
    document.head.append(scriptElement);
  }
};

const loadUmamiScript = (websiteId) => {
  console.log(websiteId, "websiteId");
  const scriptElement = document.createElement("script");
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.src = "https://eu.umami.is/script.js";
  scriptElement.dataset.websiteId = websiteId;
  scriptElement.dataset.doNotTrack = "true";
  scriptElement.dataset.autoTrack = "true";
  document.body.append(scriptElement);
};

const setGoggleFonts = (font) => {
  // console.log(font);
  if (!font) font = "Roboto";
  if (
    font != "Bw Mitga" &&
    font != "Brother1816" &&
    font != "ContaxPro75" &&
    font != "ZoojaPro" &&
    font != "AmpleSoft Pro" &&
    font != "Handelson Four" &&
    font != "MSans" &&
    font != "Museo Slab" &&
    font != "Qanelas" &&
    font != "SantElia Script" &&
    font != "FuturaBQ" &&
    font != "Blackore" &&
    font != "Argone" &&
    font != "CreamyScript" &&
    font != "Impact" &&
    font != "Montserrat" &&
    font != "Century Gothic"
  ) {
    // SI LA FONT N'EST PAS LOCALE
    var head = document.querySelectorAll("head")[0];
    var link = document.createElement("link");
    link.id = font;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href =
      "https://fonts.googleapis.com/css?family=" +
      font +
      ":wght@300;400;500;700;900";
    link.media = "all";
    head.append(link);
  }
  // console.log(font);
  return font;
};

const getStatus = (date_debut, date_fin, available) => {
  let dateBegin = new Date(date_debut);
  let dateOff = new Date(date_fin);
  let today = new Date();
  let status = "open";

  if (!available) {
    status = "offline";
  } else if (dateOff > today && dateBegin > today) {
    status = "wait";
  } else if (dateOff < today) {
    status = "finish";
  }
  return status;
};

const getAsset = (files, name, lang) => {
  if (files) {
    let asset = files.filter((s) => s.uri.includes(`/${name}-${lang}`));

    if (asset.length <= 0) {
      asset = files.filter((s) => s.uri.includes(`/${name}.`));
    }
    if (asset.length <= 0) {
      return;
    }
    return asset[0].uri;
  }
};

const setLang = (langs, file) => {
  const queryString = window.location.search;
  const urlParameters = new URLSearchParams(queryString);

  let getlang = urlParameters.get("lang");
  let lang = getlang || langs[0] || "fr";

  let op = {
    language: lang,
    langs: langs,
    askLanguage: false,
  };

  if (!getlang && op.langs && op.langs.length > 1) {
    op.askLanguage = true; // Popup pour demander la langue
  }

  if (lang === "be-nl") lang = "nl-be";
  if (lang === "uk") lang = "en";

  op.langText = file.filter((lg) => {
    return lg.lang === lang;
  });

  if (op.langText.length === 0) {
    op.langText = file.filter((lg) => {
      return lg.lang === "fr";
    });
  }

  op.language = lang;
  return op;
};

const hookForceJsonLang = async (op, lang, files) => {
  const multilang = files.find((s) => s.uri.includes("langs."));
  if (multilang?.uri) {
    const json = await getJsonLangs(multilang.uri);
    if (json) {
      const newlangText = json.data.find((lg) => lg.lang === lang);
      if (newlangText) {
        return asignNewOp(json, op, lang);
      }
    }
  }
};

const asignNewOp = (json, op, lang) => {
  const newlangText = json.data.find((lg) => lg.lang === lang);
  if (newlangText) {
    Object.assign(op, newlangText.texts);
    if (newlangText.texts.label_link1)
      op.links[0].label = newlangText.texts.label_link1;
    if (newlangText.texts.label_link2)
      op.links[1].label = newlangText.texts.label_link2;
    if (newlangText.texts.url_link1)
      op.links[0].link = newlangText.texts.url_link1;
    if (newlangText.texts.url_link2)
      op.links[1].link = newlangText.texts.url_link2;
    if (newlangText.texts.text_odr_submited)
      op.custom.text_submited = newlangText.texts.text_odr_submited;
    return op;
  }
};

const setOperation = async (ENV) => {
  try {
    const path = window.location.pathname.split("/");
    const HOST = window.location.hostname;

    let op = [
      {
        custom: {},
        logs: {},
      },
    ];

    if (path[1].length === 24 && path.length >= 2) {
      /* Accès via id mongo */
      op = await getDataFromID(path[1]);
      op.local_link = "/" + path[1];

      if (!op && path[1] != "404") {
        window.location.href = "/404";
        return false;
      }
      op.custom.host = "/" + path[1];
    } else {
      /* Accès via hostname */
      let clean_host = HOST.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split(
        "/",
      )[0];

      op = await getDataFromHost(clean_host);
      if (!op && path[1] != "404") {
        window.location.href = "/404";
        return false;
      }

      if (op.client === "dulux" && ENV != "DEV") {
        setDuluxCookies();
        op.banner_disable_cookie = true;
      }

      op.custom.host = "https://" + clean_host;
    }
    return op;
  } catch (error) {
    console.log(error);
    return false;
  }
};

const setTitle = (op) => {
  return op.custom.titre_home || op.custom.pagetitle || op.title;
};

const setPageTitle = (op) => {
  return op.custom.pagetitle || op.custom.titre_home || op.title;
};

const setStyle = (op) => {
  /* GESTION DES FONT && COULEURS */
  const fontID = setGoggleFonts(op.custom.font_family);

  let color_bg_top =
    op.custom.transparence_nav_link && op.custom.transparence_nav_link > 0
      ? hexToRgbA(
          op.custom.color_top_bg_link,
          op.custom.transparence_nav_link / 100,
        )
      : hexToRgbA(op.custom.color_top_bg_link, "0.35");

  const style = document.createElement("style");
  style.append(
    document.createTextNode(
      `:root {
            --timer : ${op.custom.memory_timer};
            --body-font-size : ${op.custom.font_size || "16"}px;
            --burger-color : ${formatToHex(
              op.custom.burger_color || op.custom.secondary_color_bg,
            )}; 
            --body-fonts: "${fontID}", "Helvetica Neue", Helvetica, Arial, sans-serif; 
            --primary-color-bg : ${formatToHex(op.custom.primary_color_bg)};
            --primary-color-txt : ${formatToHex(op.custom.primary_color_txt)}; 
            --secondary-color : ${formatToHex(op.custom.secondary_color_bg)}; 
            --custom-bgcolor : ${formatToHex(op.custom.body_background_color)}; 
            --secondary-color-txt : ${formatToHex(
              op.custom.secondary_color_txt ?? "#FFFFFF",
            )};    
            --color-top-bg-link : ${color_bg_top ?? "#FFFFFF"}; 
            --dark-mode: rgba(var(--orange), 0.15);            
            --color-top-link : ${formatToHex(op.custom.color_top_link)}; 
            --color-input-border :${formatToHex(op.custom.color_input_border)};
            --color-input-bg :${formatToHex(
              op.custom.color_input_bg ?? "#FFFFFF",
            )};           
            --color-input-txt :${formatToHex(op.custom.color_input_txt)}; 
            --color-input-bg-focus :${formatToHex(
              op.custom.color_input_bg_focus ?? "#F2F7F9",
            )};
            --color-input-txt-error: ${formatToHex(
              op.custom.input_txt_error ?? "FF0000",
            )};
            --color-input-bg-error:${formatToHex(
              op.custom.color_input_bg_error ?? "#FFFFFF",
            )};     
            --color-error-txt:${formatToHex(
              op.custom.input_txt_error ?? "#FF0000",
            )};            
            --border-radius-popup : ${op.custom.border_radius_popup ?? 0}px; 
            --border-radius-button : ${op.custom.border_radius_buttons ?? 0}px; 
            --border-radius-field : ${
              op.custom.border_radius_field ?? 0
            }px;             
            --color-bg-button-back : ${formatToHex(
              op.custom.color_text_button_back ?? "#FFFFFF",
            )}; 
            --color-text-button-back : ${formatToHex(
              op.custom.color_bg_button_back,
            )}; 
            --color-bg-valid-check : ${formatToHex(
              op.custom.color_bg_valid_check ?? "#00FF00",
            )};               
        }`,
    ),
  );
  document.head.append(style);
};

const convertTags = (mb, string) => {
  if (string) {
    let mapObject = {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      "[date_debut]": dayjs(mb?.date_debut).format("DD/MM/YYYY"),
      "[date_fin]": dayjs(mb?.date_fin).format("DD/MM/YYYY"),
      "[date_fin_achat]": dayjs(mb?.date_fin_achat).format("DD/MM/YYYY"),
      "[date_fin_regule]": dayjs(mb?.date_fin_regule).format("DD/MM/YYYY"),
      "[op_title]": mb.pagetitle,
      "[email_expediteur]": mb?.email_expediteur || "contact@promo.dev",
    };

    return string.replaceAll(
      /\[date_debut]|\[date_fin]|\[date_fin_achat]|\[date_fin_regule]|\[op_title]|\[email_expediteur]/gi,
      function (matched) {
        return mapObject[matched];
      },
    );
  }
};

export async function Init() {
  try {
    const ENV = checkEnvironment();
    let op = await setOperation(ENV);

    if (op.umami && op.umami.websiteId) {
      loadUmamiScript(op.umami.websiteId);
    }

    let submission = await getSubmission();
    if (submission) {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let date_fin_regule = new Date(op.date_fin_regule);

      if (date_fin_regule < today) {
        window.location.href = "/";
      }
    }

    op.template = "gabarit1";
    op.serverUrl = process.env.VUE_APP_ROOT_API ?? "https://api.odr.promo.dev";

    /* langues gros chantier */
    op.lang = op.langs[0] || [op.lang]; // langue par defaut
    const { language, langText, askLanguage } = setLang(op.langs, langs);
    const lang = language;
    op.langText = langText;
    op.lang = lang;
    op.askLanguage = askLanguage;

    let new_op = hookForceJsonLang(op, op.lang, op.files);
    if (new_op) op = Object.assign(op, new_op);

    // lang =
    //   submission && submission.lang
    //     ? submission.lang
    //     : urlParameters.get("lang");

    op.langText = langText[0].texts;

    /* SI NAVIGATEUR FR LANGUE TOOLBAR = FR  */
    op.langText.toolbar =
      langs.find((lg) => lg.lang === "fr")?.texts.toolbar ||
      op.langText.toolbar;

    /* MENTIONS LEGALES  */
    op.mentions = (op.mentionsLegales || op.society_editor) ?? undefined;

    /* GESTION STATUS */
    op.status =
      ENV === "DEV"
        ? "open"
        : getStatus(op.date_debut, op.date_fin, op.available);

    /* TOOLBAR */
    op.toolbar = ENV === "DEV";

    /* TYPE */
    op.type = op.type ?? "ODR";

    /* GESTION DU COMPTEUR */
    op.counter = await getCounter(
      op.counters,
      op._id,
      op.custom.compteur_limit_visible,
    );

    /* TITLE */
    op.title = setTitle(op);
    const pagetitle = setPageTitle(op);
    window.document.title = pagetitle.replaceAll(/(<([^>]+)>)/gi, "");

    /* ID DES FORMULAIRES */
    const { statusID, createID } = getFormsID(op.forms, lang);
    op.statusID = statusID;
    op.createID = createID;
    op.step = op.forms.length + 1;

    /* POLITIQUE LINK */
    op.politique_uri = getAsset(op.files, "politique", lang);
    // console.log(op.politique_uri);
    /* MODALLITES LINK */
    op.modalites_uri = getAsset(op.files, "modalites", lang);

    /* REGLEMENT LINK */
    op.reglement_uri = getAsset(op.files, "reglement", lang);

    /* GESTION DU LOGO */
    op.logo = getAsset(op.files, "logo1", lang) || "/logo1.png";

    /* BANNIERE */
    op.banner_link = getAsset(op.files, "banner_mb", lang);

    /* LOT PERDU */
    op.lot_perdu = getAsset(op.files, "lot_perdu", lang);

    /* GESTION DU BACKGROUND */
    op.background_image_link = getAsset(op.files, "background_mb", lang);

    /* FAVICON */
    let favicon = getAsset(op.files, "favicon", lang) || "/favicon.png";
    let linkfav = document.querySelector("link[rel='icon']");
    linkfav.href = `${favicon}?${Math.round(Date.now() / 1000)}`;

    /* MULTIPLE BANNIERES */
    op.banner_steps = [];
    for (let n = 1; n < 6; n++) {
      op.banner_steps[n] = getAsset(op.files, "banner_mb_step" + n, lang);
    }

    console.log(op.custom.customLegalForm);

    op.pagetitle = pagetitle;
    op.custom.text_wait = convertTags(op, op.custom?.text_wait);
    op.custom.text_submited = convertTags(op, op.custom?.text_submited);
    op.custom.text_quotafull = convertTags(op, op.custom?.text_quotafull);
    op.custom.text_off = convertTags(op, op.custom?.text_off);
    op.custom.text_home = convertTags(op, op.custom?.text_home);
    op.custom.text_finish = convertTags(op, op.custom?.text_finish);

    if (op.counter && op.counter.restant && op.counter.restant <= 0) {
      op.status = "quotafull";
    }

    setStyle(op);

    return op;
  } catch (error) {
    console.log(error);
  }
}
